import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Primler = () => {
  const [primler, setPrimler] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalMaas, setTotalMaas] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [showHistoricalData, setShowHistoricalData] = useState(false);

  useEffect(() => {
    fetchPersonelAndPrimler();
  }, [selectedMonth, selectedYear]);

  const fetchPersonelAndPrimler = async () => {
    try {
      // Personel listesini al
      const personelResponse = await axios.get('https://kobiyanimda.com/get_personnel_salaries.php');

      // Seçilen ay için satışları al
      const salesResponse = await axios.get('https://kobiyanimda.com/get_sales.php', {
        params: { month: selectedMonth, year: selectedYear },
        withCredentials: true
      });

      // Her personel için prim hesaplaması yap
      const primlerData = personelResponse.data.map(personel => {
        const personelSales = salesResponse.data.filter(
          sale => parseInt(sale.personnel_id) === parseInt(personel.id)
        );

        const totalCommission = personelSales.reduce(
          (sum, sale) => sum + parseFloat(sale.commission || 0),
          0
        );

        const totalMaas = 17002 + totalCommission;

        return {
          id: personel.id,
          name: personel.name,
          baseAmount: 17002,
          commission: totalCommission,
          totalAmount: totalMaas
        };
      });

      const genelToplam = primlerData.reduce(
        (sum, personel) => sum + personel.totalAmount,
        0
      );

      setPrimler(primlerData);
      setTotalMaas(genelToplam);
      setLoading(false);
    } catch (err) {
      console.error('Hata detayı:', err);
      setError('Veri yüklenirken bir hata oluştu: ' + err.message);
      setLoading(false);
    }
  };

  if (loading) return (
    <div className="container mx-auto px-6 py-8">
      <h2 className="text-3xl font-semibold text-gray-800">Yükleniyor...</h2>
    </div>
  );

  if (error) return (
    <div className="container mx-auto px-6 py-8">
      <h2 className="text-3xl font-semibold text-red-600">{error}</h2>
    </div>
  );

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 2
    }).format(amount);
  };

  return (
    <>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-semibold text-gray-800">Personel Maaş ve Primleri</h2>
        <button
          onClick={() => setShowHistoricalData(!showHistoricalData)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {showHistoricalData ? 'Güncel Ayı Göster' : 'Geçmiş Primleri İncele'}
        </button>
      </div>

      {showHistoricalData && (
        <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
          <div className="flex gap-4 items-center">
            <select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
              className="border rounded py-2 px-3"
            >
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {new Date(2000, i, 1).toLocaleString('tr-TR', { month: 'long' })}
                </option>
              ))}
            </select>

            <select
              value={selectedYear}
              onChange={(e) => setSelectedYear(parseInt(e.target.value))}
              className="border rounded py-2 px-3"
            >
              {Array.from(
                { length: 5 },
                (_, i) => new Date().getFullYear() - i
              ).map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>

            {selectedMonth !== new Date().getMonth() + 1 || selectedYear !== new Date().getFullYear() ? (
              <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4">
                {selectedYear === new Date().getFullYear() ? 
                  `${new Date(2000, selectedMonth-1, 1).toLocaleString('tr-TR', { month: 'long' })} ayı primlerini görüntülüyorsunuz` :
                  `${selectedYear} yılı ${new Date(2000, selectedMonth-1, 1).toLocaleString('tr-TR', { month: 'long' })} ayı primlerini görüntülüyorsunuz`
                }
              </div>
            ) : (
              <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4">
                Güncel ay primlerini görüntülüyorsunuz
              </div>
            )}
          </div>
        </div>
      )}

      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Personel Adı
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Sabit Maaş
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Komisyon
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Toplam
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {primler.map((personel) => (
                <tr key={personel.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {personel.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900">
                    {formatCurrency(personel.baseAmount)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900">
                    {formatCurrency(personel.commission)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-right font-semibold text-gray-900">
                    {formatCurrency(personel.totalAmount)}
                  </td>
                </tr>
              ))}
              <tr className="bg-gray-50 font-bold">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  GENEL TOPLAM
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900">
                  {formatCurrency(primler.length * 17002)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900">
                  {formatCurrency(totalMaas - (primler.length * 17002))}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-900">
                  {formatCurrency(totalMaas)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Primler;